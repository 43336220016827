import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {BrowserRouter} from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import {store} from './configuration/store/store';
import {i18nextInit} from './configuration/i18n/i18n';
import App from './App';
import './fonts/ClashGrotesk/ClashGrotesk-Medium.otf';

import './index.css';
import './components/banner/Banner.css';
import './components/property-card/PropertyListItemCardLatest.css';
import './components/section-advise/SectionAdvise.css';
import './components/section-services/SectionServices.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ScrollToTop from "./hooks/ScrollToTop";

i18nextInit().then(() => {
  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <BrowserRouter>
          <ScrollToTop />
          <App />
        </BrowserRouter>
      </Provider>
    </React.StrictMode>,
    document.getElementById('root')
  );
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
