import React from 'react';
import {useTranslation} from 'react-i18next';
import {Link} from "react-router-dom";

interface FooterProps {
  cookieConsent: boolean;
}

const Footer = ({ cookieConsent }: FooterProps) => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.resolvedLanguage;

  const resetCookies = () => {
      localStorage.setItem('cookieConsent', JSON.stringify(false));
      localStorage.setItem('analyticsStorage', JSON.stringify(false));
      localStorage.setItem('adStorage', JSON.stringify(false));
      window.location.reload();
  }

  return (
      <footer className="bg-white py-5 md:pt-16">

          <div className="container max-w-6xl mx-auto px-4">

              <div className="flex flex-col lg:flex-row justify-between">
                  <div className="flex flex-col justify-center items-center lg:items-start text-center lg:text-left mb-10 lg:mb-0">
                      <div className="flex justify-center lg:justify-start mb-5 w-24">
                          <img src={`${process.env.PUBLIC_URL}/images/reesman.png`} alt="Image" />
                      </div>

                      <p className="font-light text-gray-400 text-lg mb-10">Reesman. {t('footer.copyright')}</p>

                      <div className="flex items-center justify-center lg:justify-start space-x-5">
                          <a href="https://m.facebook.com/reesmanibiza/" target="_blank"
                             className="px-3 py-3 bg-gray-200 text-gray-700 rounded-full hover:bg-gray-800 hover:text-white transition ease-in-out duration-500">
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                   fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                   strokeLinejoin="round" className="feather feather-facebook">
                                  <path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z"></path>
                              </svg>
                          </a>

                          <a href="https://www.instagram.com/reesman_inmobiliaria/" target="_blank"
                             className="px-3 py-3 bg-gray-200 text-gray-700 rounded-full hover:bg-gray-800 hover:text-white transition ease-in-out duration-500">
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                   fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                   strokeLinejoin="round" className="feather feather-instagram">
                                  <rect x="2" y="2" width="20" height="20" rx="5" ry="5"></rect>
                                  <path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z"></path>
                                  <line x1="17.5" y1="6.5" x2="17.51" y2="6.5"></line>
                              </svg>
                          </a>
                      </div>
                  </div>

                  <div className="text-center lg:text-left mb-10 lg:mb-0">
                      <h4 className="font-semibold text-gray-900 text-2xl mb-6">{t('footer.juridical-services')}</h4>

                      <Link to={`/${currentLanguage}/services`}
                         className="block font-light text-gray-400 text-xl mb-6 hover:text-gray-800 transition ease-in-out duration-300">{t('footer.real-state-law')}</Link>

                      <Link to={`/${currentLanguage}/services`}
                         className="block font-light text-gray-400 text-xl mb-6 hover:text-gray-800 transition ease-in-out duration-300">{t('footer.legal-service')}</Link>

                      <Link to={`/${currentLanguage}/services`}
                         className="block font-light text-gray-400 text-xl mb-6 hover:text-gray-800 transition ease-in-out duration-300">{t('footer.real-state-tax-law')}</Link>
                  </div>

                  <div className="text-center lg:text-left mb-10 lg:mb-0">
                      <h4 className="font-semibold text-gray-900 text-2xl mb-6">{t('footer.real-state')}</h4>

                      <Link to={`/${currentLanguage}/properties?propertyStatus=rent`}
                         className="block font-light text-gray-400 text-xl mb-6 hover:text-gray-800 transition ease-in-out duration-300">{t('footer.rent')}</Link>

                      <Link to={`/${currentLanguage}/properties?propertyStatus=sale`}
                         className="block font-light text-gray-400 text-xl mb-6 hover:text-gray-800 transition ease-in-out duration-300">{t('footer.sale')}</Link>
                  </div>
              </div>

              <hr className="mt-6" />

              <div className="flex flex-col lg:flex-row justify-center lg:justify-between items-center mt-6">
                  <div className="flex justify-center items-center text-xs sm:text-sm text-gray-400">
                     <span>Made with</span>
                     <svg xmlns="http://www.w3.org/2000/svg" className="mx-1 h-5 w-5 text-red-600" viewBox="0 0 20 20"
                          fill="currentColor">
                         <path fillRule="evenodd"
                               d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z"
                               clipRule="evenodd"/>
                     </svg>
                     <span>by <a href="https://www.aldanux.es"
                                 target="_blank">Aldanux</a>.</span>
                  </div>
                  <div className="flex flex-col sm:flex-row items-center justify-center space-y-2 md:space-y-0 md:space-x-2 mt-2 sm:mt-0">
                      <Link to={`/${i18n.resolvedLanguage}/legal`} className="hover:underline text-sm text-gray-400">{t('footer.legal')}</Link>
                      <span className="hidden sm:flex">-</span>
                      <Link to={`/${i18n.resolvedLanguage}/privacy-policy`} className="hover:underline text-sm text-gray-400">{t('footer.privacy-policy')}</Link>
                      {cookieConsent &&
                          <>
                              <span className="hidden sm:flex">-</span>
                              <div className="text-sm text-gray-400">
                                  <a className="cursor-pointer hover:underline" onClick={resetCookies}>{t('footer.reset-cookies')}</a>
                              </div>
                          </>
                      }
                  </div>
              </div>

          </div>

      </footer>
  );
}

export default Footer;
