import React, {useEffect, useState} from "react";
import {Link, useLocation, useNavigate} from 'react-router-dom';
import styled from "styled-components";
import { motion } from 'framer-motion';
import { MenuToggle } from "./MenuToggle";
import {useTranslation} from "react-i18next";
import {getSupportedLanguages} from "../../configuration/i18n/i18n";

const NavLinksContainer = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
`;

const LinksWrapper = styled(motion.ul)`
  margin: 0;
  padding: 10px;
  display: flex;
  height: 100vh;
  list-style: none;
  background-color: #000;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  transition: 0.5s;
  overflow: auto;
`;

const LinkItem = styled(motion.li)`
  width: 100%;
  padding: 5px;
  color: #fff;
  font-weight: 500;
  font-size: 24px;
  margin-bottom: 10px;
  text-align: center;
`;

const menuVariants = {
    open: {
        transform: "translateY(0%)"
    },
    closed: {
        transform: "translateY(-100%)"
    }
}

const variantsLi = {
    open: {
        y: 0,
        opacity: 1,
        transition: {
            y: { stiffness: 1000, velocity: -100 }
        }
    },
    closed: {
        y: 50,
        opacity: 0,
        transition: {
            y: { stiffness: 1000 }
        }
    }
};

const menuTransition = { type: "spring", duration: 0.2, stiffness: 400, damping: 40, delay: 0.3 }

export function MobileNavLinks(props:any) {
    const { t, i18n } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
    const [isOpen, setOpen] = useState(false);
    const [showLanguages, setLanguages] = useState(false);
    const [languages] = useState<string[]>(getSupportedLanguages());
    const handleClick = () => {
        setLanguages(!showLanguages);
    }
    const onClickLanguage = (language: string) => {
        const currentLanguage = i18n.resolvedLanguage;
        i18n.changeLanguage(language).then(() => {
            navigate(location.pathname.replace(currentLanguage, language));
            handleClick();
        });
        setOpen(!isOpen)
    }

    useEffect(() => {
        (isOpen) ? document.body.style.overflow = 'hidden' : document.body.style.overflow = 'visible'
    });

    return (
        <NavLinksContainer>
            <MenuToggle isOpen={isOpen} toggle={() => setOpen(!isOpen)} />
                <LinksWrapper initial={false} animate={isOpen ? 'open' : 'closed'} variants={menuVariants} transition={menuTransition}>
                    <LinkItem variants={variantsLi} whileHover={{ scale: 1.1 }}>
                        <Link to={`/${i18n.resolvedLanguage}`} onClick={() => setOpen(!isOpen)}>{t('nav-items.home')}</Link>
                    </LinkItem>
                    <LinkItem variants={variantsLi} whileHover={{ scale: 1.1 }}>
                        <Link to={`/${i18n.resolvedLanguage}/properties?propertyStatus=sale`} onClick={() => setOpen(!isOpen)}>{t('nav-items.sale')}</Link>
                    </LinkItem>
                    <LinkItem variants={variantsLi} whileHover={{ scale: 1.1 }}>
                        <Link to={`/${i18n.resolvedLanguage}/properties?propertyStatus=rent`} onClick={() => setOpen(!isOpen)}>{t('nav-items.rent')}</Link>
                    </LinkItem>
                    <LinkItem variants={variantsLi} whileHover={{ scale: 1.1 }}>
                        <Link to={`/${i18n.resolvedLanguage}/register-property`} onClick={() => setOpen(!isOpen)}>{t('nav-items.register')}</Link>
                    </LinkItem>
                    <LinkItem variants={variantsLi} whileHover={{ scale: 1.1 }}>
                        <Link to={`/${i18n.resolvedLanguage}/services`} onClick={() => setOpen(!isOpen)}>{t('nav-items.services')}</Link>
                    </LinkItem>
                    <LinkItem variants={variantsLi} whileHover={{ scale: 1.1 }}>
                        <Link to={`/${i18n.resolvedLanguage}/contact`} onClick={() => setOpen(!isOpen)}>{t('nav-items.contact')}</Link>
                    </LinkItem>
                    <LinkItem variants={variantsLi} whileHover={{ scale: 1.1 }}>
                        {languages && languages.map(language => {
                            if(i18n.resolvedLanguage !== language) {
                                return <span key={language} onClick={() => onClickLanguage(language)} className="flex flew-row justify-center items-center text-white block px-4 py-2 cursor-pointer" role="menuitem" id="menu-item-0">
                                    <img className="h-6 w-6 mr-2" src={`${process.env.PUBLIC_URL}/images/flags/flag_${language === 'es' ? 'es' : 'en'}.png`}
                                         alt={`${language === 'es' ? 'español' : 'english'}`} />{language.toUpperCase()}
                                </span>;
                            }
                        })}
                    </LinkItem>
                </LinksWrapper>
        </NavLinksContainer>
    );
}
