import React from 'react';
import {PropertyListItem} from '../../views/properties/models/property-list-item';
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import parse from 'html-react-parser';

interface PropertyListItemCardProps {
  property: PropertyListItem
}

const PropertyListItemCardRelated = ({property}: PropertyListItemCardProps) => {
    const { t, i18n } = useTranslation();
    const currentLanguage = i18n.resolvedLanguage;

    function commify(n:any) {
        if(n) {
            let parts = n.toString().split(".");
            const numberPart = parts[0];
            const decimalPart = parts[1];
            const thousands = /\B(?=(\d{3})+(?!\d))/g;
            return numberPart.replace(thousands, ".") + (decimalPart ? "," + decimalPart : "");
        } else {
            return 0;
        }
    }

    return (
        <div className={`w-full p-4`}>
            <Link to={`/${currentLanguage}/properties/${property.slug}`} className="c-card block bg-white shadow-md hover:shadow-lg rounded-lg overflow-hidden">
                <div className="relative pb-48 overflow-hidden">
                    <img className="absolute inset-0 h-full w-full object-cover"
                         src={property.imageUrl}
                         alt="" />
                    {property.operation &&
                        <div className={(property.operation === 'Alquiler') ||  (property.operation === 'Rent') ? `absolute bottom-0 right-0 px-2 bg-green-400 rounded-tl-md` : `absolute bottom-0 right-0 px-2 bg-red-400 rounded-tl-md`}>
                            <span className="text-sm text-white uppercase">{property.operation}</span>
                        </div>
                    }
                </div>
                <div className="p-4 flex flex-col h-48">
                    <h2 className="text-lg font-bold">{property.name}</h2>
                    {!!property.property_address &&
                        <div className="flex justify-start items-center mt-2 space-x-2">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-red-400" fill="none"
                                 viewBox="0 0 24 24"
                                 stroke="currentColor" strokeWidth="2">
                                <path strokeLinecap="round" strokeLinejoin="round"
                                      d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"/>
                                <path strokeLinecap="round" strokeLinejoin="round"
                                      d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"/>
                            </svg>
                            <span className="text-sm">{property.property_address}</span>
                        </div>
                    }
                    <p className="text-sm h-32 mt-2">{property.extract && parse(`${property.extract}`)}</p>
                    <div className="mt-3 flex justify-end items-center">
                        <span className="font-bold text-xl">{property.price ? commify(property.price) : t('card-properties.consult')}</span>&nbsp;
                        {property.price ? <span className="text-sm font-semibold">€</span> : '' }
                    </div>
                </div>
                <div className="p-4 border-t border-b text-xs text-gray-700">
                    <div className="grid grid-cols-2 items-center justify-between">
                        {!!property.property_size &&
                            <span className="flex items-center mb-1">
                                <svg className="h- w-6 text-gray-600 fill-current mr-3" xmlns="http://www.w3.org/2000/svg"
                                     viewBox="0 0 448 512"><path
                                    d="M432 184V59.31L235.3 256 432 452.7V328c0-4.422 3.58-8 7.998-8 4.424 0 8 3.578 8 8v144c0 4.426-3.584 8-8 8h-144c-4.398 0-7.998-3.6-7.998-8a8 8 0 0 1 8-8h124.7L224 267.3 27.31 464H152c4.4 0 8 3.6 8 8s-3.6 8-8 8H8c-4.424 0-8-3.6-8-8V328c0-4.4 3.58-8 8-8a7.996 7.996 0 0 1 7.998 8v124.7L212.7 256 15.1 59.31V184c0 4.4-2.68 8-7.1 8-4.424 0-8-3.6-8-8V40c0-4.43 3.584-8 8-8h144c4.4 0 8 3.59 8 8 0 4.42-3.6 8-8 8H27.31L224 244.7 420.7 48H296c-4.4 0-8-3.58-8-8 0-4.41 3.6-8 8-8h144c4.4 0 8 3.58 8 8v144c0 4.4-3.6 8-8 8s-8-3.6-8-8z"/></svg>
                                <span
                                    className="ml-2">{property.property_size} {parse(`${t('card-properties.area')}`)}</span>
                            </span>
                        }
                        {!!property.bedrooms &&
                            <span className="flex items-center">
                                <svg className="h-6 w-6 text-gray-600 fill-current mr-3"
                                     xmlns="http://www.w3.org/2000/svg"
                                     viewBox="0 0 24 24">
                                      <path
                                          d="M0 16L3 5V1a1 1 0 0 1 1-1h16a1 1 0 0 1 1 1v4l3 11v5a1 1 0 0 1-1 1v2h-1v-2H2v2H1v-2a1 1 0 0 1-1-1v-5zM19 5h1V1H4v4h1V4a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v1h2V4a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v1zm0 1v2a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1V6h-2v2a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V6H3.76L1.04 16h21.92L20.24 6H19zM1 17v4h22v-4H1zM6 4v4h4V4H6zm8 0v4h4V4h-4z"></path>
                                </svg>
                                <span
                                    className="ml-2">{property.bedrooms} {property.bedrooms > 1 ? t('card-properties.rooms') : t('card-properties.room')}</span>
                            </span>
                        }
                        {!!property.bathrooms &&
                            <span className="flex items-center mb-1">
                                <svg className="h-6 w-6 text-gray-600 fill-current mr-3" xmlns="http://www.w3.org/2000/svg"
                                     viewBox="0 0 24 24">
                                      <path fillRule="evenodd"
                                            d="M17.03 21H7.97a4 4 0 0 1-1.3-.22l-1.22 2.44-.9-.44 1.22-2.44a4 4 0 0 1-1.38-1.55L.5 11h7.56a4 4 0 0 1 1.78.42l2.32 1.16a4 4 0 0 0 1.78.42h9.56l-2.9 5.79a4 4 0 0 1-1.37 1.55l1.22 2.44-.9.44-1.22-2.44a4 4 0 0 1-1.3.22zM21 11h2.5a.5.5 0 1 1 0 1h-9.06a4.5 4.5 0 0 1-2-.48l-2.32-1.15A3.5 3.5 0 0 0 8.56 10H.5a.5.5 0 0 1 0-1h8.06c.7 0 1.38.16 2 .48l2.32 1.15a3.5 3.5 0 0 0 1.56.37H20V2a1 1 0 0 0-1.74-.67c.64.97.53 2.29-.32 3.14l-.35.36-3.54-3.54.35-.35a2.5 2.5 0 0 1 3.15-.32A2 2 0 0 1 21 2v9zm-5.48-9.65l2 2a1.5 1.5 0 0 0-2-2zm-10.23 17A3 3 0 0 0 7.97 20h9.06a3 3 0 0 0 2.68-1.66L21.88 14h-7.94a5 5 0 0 1-2.23-.53L9.4 12.32A3 3 0 0 0 8.06 12H2.12l3.17 6.34z"></path>
                                </svg>
                                <span
                                    className="ml-2">{property.bathrooms} {property.bathrooms > 1 ? t('card-properties.bathrooms') : t('card-properties.bathroom')}</span>
                            </span>
                        }
                        {!!property.garages &&
                            <span className="flex items-center">
                                <svg className="h-6 w-6 text-gray-600 fill-current mr-3"
                                     xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path
                                    d="M632.5 194.5c7.5 4.7 9.7 14.6 5 22-4.7 7.5-14.6 9.7-22 5L320 34.92 24.55 221.5c-7.48 4.7-17.357 2.5-22.076-5-4.718-7.4-2.487-17.3 4.984-22L311.5 2.472a15.85 15.85 0 0 1 17 0l304 192.028zM184 376c0-13.3 10.7-24 24-24s24 10.7 24 24-10.7 24-24 24-24-10.7-24-24zm272 0c0 13.3-10.7 24-24 24s-24-10.7-24-24 10.7-24 24-24 24 10.7 24 24zM252.2 176h135.6c28.6 0 53.7 18.9 61.5 46.4l20.8 75.7c25.3 13.7 41.9 39 41.9 69.9v128c0 8.8-7.2 16-16 16s-16-7.2-16-16v-32H160v32c0 8.8-7.2 16-16 16s-16-7.2-16-16V368c0-30.9 16.6-56.2 41-69.9l21.7-75.7c7.8-27.5 32.9-46.4 61.5-46.4zm0 32c-14.3 0-26.8 9.5-30.8 23.2L205.2 288H434.8l-16.2-56.8c-4-13.7-16.5-23.2-30.8-23.2H252.2zM480 368c0-26.5-21.5-48-48-48H208c-26.5 0-48 21.5-48 48v64h320v-64z"/></svg>
                                <span
                                    className="ml-2">{property.garages} {property.garages > 1 ? t('card-properties.garages') : t('card-properties.garage')}</span>
                            </span>
                        }
                    </div>
                </div>
            </Link>
        </div>
    );
}

export default PropertyListItemCardRelated;
