import React, {useEffect, useState} from 'react';
import Banner from "../../components/banner/Banner";
import {PropertyListItem} from "../properties/models/property-list-item";
import SectionAdvise from "../../components/section-advise/SectionAdvise";
import SectionContact from "../../components/section-contact/SectionContact";
import {useTranslation} from "react-i18next";
import {fetchLastProperties, Property} from "../../services/propertyService";
import PropertyListItemCardRelated from "../../components/property-card/PropertyListItemCardRelated";
import Slider from "react-slick";
import SectionLegalHome from "../../components/section-legal-home/SectionLegalHome";
import parse from 'html-react-parser';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import {getSupportedLanguages} from "../../configuration/i18n/i18n";

const propertiesToListItems = (properties: Property[], language: string): PropertyListItem[] => {
    return properties.map(property => {
        return {
            id: property.id,
            name: property.attributes.name,
            extract: property.attributes.extract[language],
            description: property.attributes.description[language],
            slug: property.attributes.slug,
            operation: property.attributes.property_operation[language],
            price: property.attributes.price,
            property_size: property.attributes.property_size,
            property_size_useful: property.attributes.property_size_useful,
            property_size_plot: property.attributes.property_size_plot,
            bedrooms: property.attributes.bedrooms,
            bathrooms: property.attributes.bathrooms,
            garages: property.attributes.garages,
            imageUrl: property.attributes.banner_image,
        };
    });
}

function SampleNextArrow(props:any) {
    const { className, style, onClick } = props;
    return (
        <div className={className} style={{ ...style, right: 10, zIndex: 25 }} onClick={onClick}>
            <svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10 text-black bg-white border border-gray-300 rounded-full p-2" fill="none" viewBox="0 0 24 24"
                 stroke="currentColor" strokeWidth="2">
                <path strokeLinecap="round" strokeLinejoin="round" d="M9 5l7 7-7 7"/>
            </svg>
        </div>
    );
}

function SamplePrevArrow(props:any) {
    const { className, style, onClick } = props;
    return (
        <div className={className} style={{ ...style, left: -10, zIndex: 25, overflow: "visible" }} onClick={onClick}>
            <svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10 text-black bg-white border border-gray-300 rounded-full p-2" fill="none" viewBox="0 0 24 24" stroke="currentColor"
                 strokeWidth="2">
                <path strokeLinecap="round" strokeLinejoin="round" d="M15 19l-7-7 7-7"/>
            </svg>
        </div>
    );
}

const HomeView = () => {
  const {t, i18n} = useTranslation();
  const [rawProperties, setRawProperties] = useState<Array<Property>>([]);
  const [properties, setProperties] = useState<Array<PropertyListItem>>([]);
  const currentURL = window.location.href;
  const segments = currentURL.split('/');

    useEffect(() => {
        fetchLastProperties()
            .then(setRawProperties);
    }, []);

    useEffect(() => {
        setProperties(propertiesToListItems(rawProperties, i18n.resolvedLanguage));
    }, [rawProperties, i18n.resolvedLanguage]);

  const settings = {
    autoplay:true,
    autoplaySpeed:5000,
    centerMode: false,
    speed:500,
    variableWidth: false,
    vertical: false,
    infinite: true,
    slidesToShow:1,
    slidesToScroll:1,
    pauseOnHover:false,
    dots:false,
    pauseOnDotsHover:false,
    cssEase:'ease-in-out',
    fade:true,
    draggable:false,
    arrows: false,
    zIndex: 1,
  };

  const settingsLatest = {
    autoplay:true,
    autoplaySpeed:5000,
    centerMode: false,
    speed:500,
    variableWidth: false,
    vertical: false,
    infinite: true,
    slidesToShow:3,
    slidesToScroll:3,
    pauseOnHover:false,
    dots:false,
    arrows: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
        {
            breakpoint: 1440,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
            }
        },
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                arrows: false
            }
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                arrows: false
            }
        },
        {
            breakpoint: 640,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                arrows: false
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows:false
            }
        }
    ]
  }

  return (
      <HelmetProvider>
          <Helmet>
              <title>{t('seo.title')}</title>
              <meta name='description' content={t('seo.description')} />
              <meta name='robots' content='index,follow' />
              <link rel='canonical' href={currentURL} />
              {getSupportedLanguages().map(lang => {
                  return <link key={lang} rel="alternate" hrefLang={lang} href={`https://www.reesman.es/${lang}/${segments[4]}`} />
              })}
          </Helmet>
        <section className="relative items-center justify-center w-full overflow-x-hidden">
          <Banner {...settings} />

          <SectionLegalHome />

          <SectionAdvise />

          <div data-aos={"fade-up"} className="max-w-6xl mx-auto pt-20">
              <h1 className="text-header-gradient font-light text-gray-900 text-4xl text-center leading-normal mb-6">
                  {parse(t('titles-sections.features-properties'))}
              </h1>
          </div>

          {properties.length &&
            <section data-aos={"fade-up"} className="max-w-6xl mx-auto pb-20">
                <div className="container mx-auto">
                    <Slider {...settingsLatest} className="flex flex-wrap lg:-mx-4 slider-related">
                        {properties.slice(0, 4).map(property =>
                            <PropertyListItemCardRelated key={property.id}
                                                         property={property} />
                        )}
                    </Slider>
                </div>
            </section>
          }

          <SectionContact />
        </section>
      </HelmetProvider>
  );
}

export default HomeView;
