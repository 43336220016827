import {API_URL} from '../constants';

export interface ResponseWrapper {
  data: Property[];
}

export interface ResponseWrapperProperty {
  data: Property;
}

export interface Property {
  type: string;
  id: string;
  attributes: PropertyAttributes;
  links: DataLinks;
}

export interface PropertyAttributes {
  name: string;
  slug: string;
  extract: Translations;
  description: Translations;
  price: number;
  bedrooms: number;
  bathrooms: number;
  garages: number;
  property_id: string;
  property_operation: Translations;
  property_status: Translations;
  property_size: number;
  property_size_useful: any;
  property_size_plot: any;
  property_address: string;
  property_features: TranslationsArray;
  banner_image: string;
  media_gallery: any[];
}

export interface Translations {
  [key: string]: string;
}

export interface TranslationsArray {
  [key: string]: string[];
}

export interface DataLinks {
  self: string;
}

export interface GetPropertiesParameters {
  propertyStatus?: null | string;
  propertyType?: null | string;
  city?: null | string;
  zones?: null | string[];
  priceFrom?: null | string;
  priceTo?: null | string;
  propertyFeatures?: null | string[];
  rooms?: null | string;
  propertyId?: null | string;
}

export interface GetSlugParameters {
  year: undefined | number;
  month: undefined | number;
  day: undefined | number;
  slug: undefined | string;
}

export const fetchProperties = (parameters: GetPropertiesParameters): Promise<Property[]> => {
  let queryParameters: string[] = [];

  if (parameters.propertyStatus) {
    queryParameters = [...queryParameters, `filter[propertyStatusId]=${parameters.propertyStatus}`];
  }

  if (parameters.propertyType) {
    queryParameters = [...queryParameters, `filter[propertyTypeId]=${parameters.propertyType}`];
  }

  if (parameters.city) {
    queryParameters = [...queryParameters, `filter[cityId]=${parameters.city}`];
  }

  if (parameters.zones && parameters.zones.length > 0) {
    queryParameters = [...queryParameters, `filter[zoneId]=${parameters.zones.join(',')}`];
  }

  if (parameters.priceFrom || parameters.priceTo) {
    const priceFilter = `${parameters.priceFrom || 0},${parameters.priceTo || 9999999}`
    queryParameters = [...queryParameters, `filter[price]=${priceFilter}`];
  }

  if (parameters.propertyFeatures && parameters.propertyFeatures.length > 0) {
    queryParameters = [...queryParameters, `filter[features]=${parameters.propertyFeatures.join(',')}`];
  }

  if (parameters.rooms) {
    queryParameters = [...queryParameters, `filter[rooms]=${parameters.rooms}`];
  }

  if (parameters.propertyId) {
    queryParameters = [...queryParameters, `filter[property_id]=${parameters.propertyId}`];
  }

  return fetch(`${API_URL}/api/v1/properties?${queryParameters.join('&')}`, {
    headers: {
      Accept: 'application/vnd.api+json',
    }
  })
    .then(body => body.json())
    .then((responseWrapper: ResponseWrapper) => responseWrapper.data);
}

export const fetchLastProperties = (): Promise<Property[]> => {

  return fetch(`${API_URL}/api/v1/last-properties`, {
    headers: {
      Accept: 'application/vnd.api+json',
    }
  })
      .then(body => body.json())
      .then((responseWrapper: ResponseWrapper) => responseWrapper.data);
}

export const fetchProperty = (parameters: GetSlugParameters): Promise<Property> => {
  let queryParameters: string[] = [];

  if (parameters.year) {
    queryParameters = [...queryParameters, `${parameters.year}`];
  }

  if (parameters.month) {
    queryParameters = [...queryParameters, `${parameters.month}`];
  }

  if (parameters.day) {
    queryParameters = [...queryParameters, `${parameters.day}`];
  }

  if (parameters.slug) {
    queryParameters = [...queryParameters, `${parameters.slug}`];
  }

  return fetch(`${API_URL}/api/v1/properties/${queryParameters.join('/')}`, {
    headers: {
      Accept: 'application/vnd.api+json',
    }
  })
      .then(body => body.json())
      .then((responseWrapper: ResponseWrapperProperty) => responseWrapper.data);
}
