import React from 'react';
import {useTranslation} from 'react-i18next';
import {Link} from "react-router-dom";
import parse from 'html-react-parser';

const SectionLegalHome = () => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.resolvedLanguage;

  return (
      <section className="bg-white py-12 md:py-24 xl:relative">

          <div className="max-w-screen-xl mx-auto px-4">
              <div className="flex flex-col xl:flex-row xl:justify-center xl:items-center xl:space-x-6">

                  <div className="hidden xl:block w-full xl:w-1/2 z-10" data-aos={"fade-right"}>
                      <img className="rounded-md" src={`${process.env.PUBLIC_URL}/images/man_sign.jpg`} alt="Feature img" />
                  </div>

                  <div data-aos={"fade-left"} className="w-full xl:w-1/2">

                      <h1 data-aos={"fade-up"} className="text-header-gradient font-light text-gray-900 text-4xl text-center lg:text-left leading-normal mb-10">
                          {parse(t('titles-sections.legal-and-real-state-services'))}
                      </h1>

                      <div data-aos={"fade-up"} className="flex flex-col md:flex-row justify-center md:justify-start xl:justify-start md:space-x-4 mb-20">
                          <div
                              className="px-8 h-20 mx-auto md:mx-0 bg-gray-200 rounded-lg flex items-center justify-center mb-5 md:mb-0">
                              <svg xmlns="http://www.w3.org/2000/svg" className="h-7 w-7" fill="none"
                                   viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                  <path strokeLinecap="round" strokeLinejoin="round"
                                        d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"/>
                              </svg>
                          </div>

                          <div className="text-center md:text-left">
                              <h4 className="font-semibold text-gray-900 text-2xl mb-2">{t('legal-home.real-state-law')}</h4>
                              <p className="font-normal text-gray-400 text-xl leading-relaxed">{t('legal-home.real-state-law-text')}</p>
                          </div>
                      </div>

                      <div data-aos={"fade-up"} className="flex flex-col md:flex-row justify-center md:justify-start xl:justify-start md:space-x-4 mb-20">
                          <div
                              className="px-8 h-20 mx-auto md:mx-0 bg-gray-200 rounded-lg flex items-center justify-center mb-5 md:mb-0">
                              <svg xmlns="http://www.w3.org/2000/svg" className="h-7 w-7" fill="none"
                                   viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                  <path strokeLinecap="round" strokeLinejoin="round"
                                        d="M21 13.255A23.931 23.931 0 0112 15c-3.183 0-6.22-.62-9-1.745M16 6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2m4 6h.01M5 20h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"/>
                              </svg>
                          </div>

                          <div className="text-center md:text-left">
                              <h4 className="font-semibold text-gray-900 text-2xl mb-2">{t('legal-home.legal-service')}</h4>
                              <p className="font-normal text-gray-400 text-xl leading-relaxed">{t('legal-home.legal-service-text')}</p>
                          </div>
                      </div>

                      <div data-aos={"fade-up"} className="flex flex-col md:flex-row justify-center md:justify-start xl:justify-start md:space-x-4">
                          <div
                              className="px-8 h-20 mx-auto md:mx-0 bg-gray-200 rounded-lg flex items-center justify-center mb-5 md:mb-0">
                              <svg xmlns="http://www.w3.org/2000/svg" className="h-7 w-7" fill="none"
                                   viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                  <path d="M12 14l9-5-9-5-9 5 9 5z"/>
                                  <path
                                      d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z"/>
                                  <path strokeLinecap="round" strokeLinejoin="round"
                                        d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222"/>
                              </svg>
                          </div>

                          <div className="text-center md:text-left">
                              <h4 className="font-semibold text-gray-900 text-2xl mb-2">{t('legal-home.real-state-tax-law')}</h4>
                              <p className="font-normal text-gray-400 text-xl leading-relaxed">{t('legal-home.real-state-tax-law-text')}</p>
                          </div>
                      </div>

                      <div className="flex justify-center items-center mt-12">
                          <Link to={`/${currentLanguage}/services`} className="w-32 py-4 text-center font-semibold text-lg text-white bg-gray-700 rounded-xl hover:bg-gray-900 transition ease-in-out duration-500">
                              {t('legal-home.read-more')}
                          </Link>
                      </div>
                  </div>

              </div>

          </div>
      </section>
  );
}

export default SectionLegalHome;
