import {API_URL} from '../constants';

export interface ResponseWrapper<AttributesType> {
  data: Data<AttributesType>[];
}

export interface Data<AttributesType> {
  type: string;
  id: string;
  attributes: AttributesType;
  links: DataLinks;
}

export interface DataLinks {
  self: string;
}

export interface AttributesTranslation {
  id: string;
  language: string;
  value: string;
  created_at: string;
  updated_at: string;
}

export interface PropertyStatusAttributes {
  name_translation_key: string;
  slug: string;
  translations: AttributesTranslation[];
}

export interface DataRegisterProperty {
  nombre: string;
  telefono: string;
  email: string;
  property_status_id: number;
  property_type_id: number;
  precio: number;
  poblacion: string;
  zona: string;
  descripcion_inmueble: string;
  acepta_politica: string;
  idioma: string;
}

export interface DataContact {
  nombre: string;
  telefono: string;
  email: string;
  property_status_id: number;
  property_type_id: number;
  precio: number;
  poblacion: string;
  zona: string;
  mensaje: string;
  acepta_politica: string;
  idioma: string;
}

export interface DataToken {
  token: string;
}

export const getPropertiesStatus = (): Promise<Data<PropertyStatusAttributes>[]> => {
  return fetch(`${API_URL}/api/v1/properties-status`, {
    headers: {
      Accept: 'application/vnd.api+json',
    }
  })
    .then(body => body.json())
    .then((responseWrapper: ResponseWrapper<PropertyStatusAttributes>) => responseWrapper.data);
}

export interface PropertyTypeAttributes {
  name_translation_key: string;
  slug: string;
  translations: AttributesTranslation[];
}

export const getPropertiesTypes = (): Promise<Data<PropertyTypeAttributes>[]> => {
  return fetch(`${API_URL}/api/v1/properties-types`, {
    headers: {
      Accept: 'application/vnd.api+json',
    }
  })
    .then(body => body.json())
    .then((propertyStatusWrapper: ResponseWrapper<PropertyTypeAttributes>) => propertyStatusWrapper.data);
}

export interface CityAttributes {
  name: string;
}

export const getCities = (): Promise<Data<CityAttributes>[]> => {
  return fetch(`${API_URL}/api/v1/cities`, {
    headers: {
      Accept: 'application/vnd.api+json',
    }
  })
    .then(body => body.json())
    .then((propertyStatusWrapper: ResponseWrapper<CityAttributes>) => propertyStatusWrapper.data);
}

export interface ZoneAttributes {
  name: string;
  slug: string;
}

export const getZones = (cityId?: string | null): Promise<Data<ZoneAttributes>[]> => {
  return fetch(`${API_URL}/api/v1/zones?city_id=${cityId}`, {
    headers: {
      Accept: 'application/vnd.api+json',
    }
  })
    .then(body => body.json())
    .then((propertyStatusWrapper: ResponseWrapper<ZoneAttributes>) => propertyStatusWrapper.data);
}

export interface PropertyFeatureAttributes {
  name_translation_key: string;
  slug: string;
  translations: AttributesTranslation[];
}

export const getPropertyFeatures = (): Promise<Data<PropertyFeatureAttributes>[]> => {
  return fetch(`${API_URL}/api/v1/properties-features`, {
    headers: {
      Accept: 'application/vnd.api+json',
    }
  })
    .then(body => body.json())
    .then((propertyStatusWrapper: ResponseWrapper<PropertyFeatureAttributes>) => propertyStatusWrapper.data);
}

export const sendRegisterProperty = (data:DataRegisterProperty) => {
  return fetch(`${API_URL}/api/v1/register-property`, {
    method: 'POST',
    headers: {
      Accept: 'application/vnd.api+json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data)
  })
      .then(body => body.json());
}

export const sendContact = (data:DataContact) => {
  return fetch(`${API_URL}/api/v1/contact`, {
    method: 'POST',
    headers: {
      Accept: 'application/vnd.api+json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data)
  })
      .then(body => body.json());
}

export const verifyToken = (data:DataToken) => {
  return fetch(`${API_URL}/api/v1/verifyToken`, {
    method: 'POST',
    headers: {
      Accept: 'application/vnd.api+json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data)
  })
      .then(body => body.json());
}
