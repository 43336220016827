import React, {useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import Header from './components/header/Header';
import Routing from './components/routing/Routing';
import Footer from './components/footer/Footer';
import CookiesCard from "./components/cookies/Cookies";
import AOS from 'aos';
import 'aos/dist/aos.css';
import './App.css';

const excludedRoutes = [
  '/about',
];

function App() {
  const location = useLocation();
  const [headerHidden, setHeaderHidden] = useState<boolean>(false);
  const [cookieConsent, setCookieConsent] = useState<boolean>(false);

  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    setHeaderHidden(excludedRoutes.includes(location.pathname));
  }, [location]);

  return (
    <div className="flex flex-col min-h-screen">
      <Header hidden={headerHidden} />
      <CookiesCard onChange={setCookieConsent} />
      <div className="flex-grow">
        <Routing />
      </div>
      <div className="w-full bg-black bg-opacity-90 text-white">
        <Footer cookieConsent={cookieConsent} />
      </div>
    </div>
  );
}

export default App;
