import React from 'react';
import {useTranslation} from "react-i18next";
import parse from 'html-react-parser';
import {Helmet, HelmetProvider} from "react-helmet-async";
import {getSupportedLanguages} from "../../configuration/i18n/i18n";

const RegisterPropertyView = () => {
  const {t} = useTranslation();
  const currentURL = window.location.href;
  const segments = currentURL.split('/');

  return (
      <HelmetProvider>
        <Helmet>
          <title>{t('seo.services-title')}</title>
          <meta name='description' content={t('seo.services-description')} />
          <meta name='robots' content='index,follow' />
          <link rel='canonical' href={currentURL} />
          {getSupportedLanguages().map(lang => {
            return <link key={lang} rel="alternate" hrefLang={lang} href={`https://www.reesman.es/${lang}/${segments[4]}`} />
          })}
        </Helmet>
        <div className="max-w-6xl mx-auto mt-32 lg:mt-56 overflow-x-hidden">
            <div className="flex flex-col md:flex-row items-center py-6">
                <div data-aos={"fade-right"} className="w-full md:w-1/2 pr-3 text-left">
                    <h1 className="text-4xl font-light leading-normal text-center md:text-left px-2 lg:px-0 text-gray-900 text-header-gradient max-w-3xl">
                        {parse(t('services.legal-service'))}
                    </h1>
                    <p className="my-8 mr-0 lg:max-w-lg px-2 lg:px-0">
                        {parse(t('services.legal-service-intro'))}
                    </p>
                    <div className="my-8 mr-0 lg:max-w-lg px-2 lg:px-0">
                        {parse(t('services.legal-service-ul-1'))}
                    </div>
                </div>
                <div data-aos={"fade-left"} className="w-full md:w-1/2 mt-0 flex flex-col justify-center px-2 md:px-0">
                    <div className="max-w-none flex justify-center items-center">
                        <img className="rounded-3xl" src={`${process.env.PUBLIC_URL}/images/1.jpg`} />
                    </div>
                </div>
            </div>
            <div className="flex flex-col md:flex-row items-center py-10 lg:py-14">
                <div data-aos={"fade-right"} className="w-full md:w-1/2 mt-0 flex flex-col justify-center px-2 lg:px-0">
                    <div className="max-w-none flex justify-center items-center">
                        <img className="rounded-3xl" src={`${process.env.PUBLIC_URL}/images/2.jpg`} />
                    </div>
                </div>
                <div data-aos={"fade-left"} className="w-full md:w-1/2 pr-3 text-left lg:ml-16 mt-2 md:mt-0">
                    <div className="my-8 mr-0 lg:max-w-lg px-2 lg:px-0">
                        {parse(t('services.legal-service-ul-2'))}
                    </div>
                </div>
            </div>
            <div className="flex flex-col md:flex-row items-center py-10 lg:py-14">
                <div data-aos={"fade-right"} className="w-full md:w-1/2 pr-3 text-left">
                    <div className="my-8 mr-0 lg:max-w-lg px-2 lg:px-0">
                        {parse(t('services.legal-service-ul-3'))}
                    </div>
                </div>
                <div data-aos={"fade-left"} className="w-full md:w-1/2 mt-0 flex flex-col justify-center px-2 md:px-0">
                    <div className="max-w-none flex justify-center items-center">
                        <img className="rounded-3xl" src={`${process.env.PUBLIC_URL}/images/3.jpg`} />
                    </div>
                </div>
            </div>
            <div className="flex flex-col md:flex-row items-center py-10 lg:py-14">
                <div data-aos={"fade-right"} className="w-full md:w-1/2 mt-0 flex flex-col justify-center px-2 lg:px-0">
                    <div className="max-w-none flex justify-center items-center">
                        <img className="rounded-3xl" src={`${process.env.PUBLIC_URL}/images/4.jpg`} />
                    </div>
                </div>
                <div data-aos={"fade-left"} className="w-full md:w-1/2 pr-3 text-left lg:ml-16 mt-2 md:mt-0">
                    <div className="my-8 mr-0 lg:max-w-lg px-2 lg:px-0">
                        {parse(t('services.legal-service-ul-4'))}
                    </div>
                </div>
            </div>
            <div className="flex flex-col md:flex-row items-center py-10 lg:py-14">
                <div data-aos={"fade-right"} className="w-full md:w-1/2 pr-3 text-left">
                    <div className="my-8 mr-0 lg:max-w-lg px-2 lg:px-0">
                        {parse(t('services.legal-service-ul-5'))}
                    </div>
                </div>
                <div data-aos={"fade-left"} className="w-full md:w-1/2 mt-0 flex flex-col justify-center px-2 md:px-0">
                    <div className="max-w-none flex justify-center items-center">
                        <img className="rounded-3xl" src={`${process.env.PUBLIC_URL}/images/5.jpg`} />
                    </div>
                </div>
            </div>
            <div className="flex flex-col md:flex-row items-center py-10 lg:py-14">
                <div data-aos={"fade-right"} className="w-full md:w-1/2 mt-0 flex flex-col justify-center px-2 lg:px-0">
                    <div className="max-w-none flex justify-center items-center">
                        <img className="rounded-3xl" src={`${process.env.PUBLIC_URL}/images/6.jpg`} />
                    </div>
                </div>
                <div data-aos={"fade-left"} className="w-full md:w-1/2 pr-3 text-left lg:ml-16 mt-2 md:mt-0">
                    <div className="my-8 mr-0 lg:max-w-lg px-2 lg:px-0">
                        {parse(t('services.legal-service-ul-6'))}
                    </div>
                </div>
            </div>
            <div className="flex flex-col md:flex-row items-center py-10 lg:py-14">
                <div data-aos={"fade-right"} className="w-full md:w-1/2 pr-3 text-left">
                    <div className="my-8 mr-0 lg:max-w-lg px-2 lg:px-0">
                        {parse(t('services.legal-service-ul-7'))}
                    </div>
                </div>
                <div data-aos={"fade-left"} className="w-full md:w-1/2 mt-0 flex flex-col justify-center px-2 md:px-0">
                    <div className="max-w-none flex justify-center items-center">
                        <img className="rounded-3xl" src={`${process.env.PUBLIC_URL}/images/7.jpg`} />
                    </div>
                </div>
            </div>
            <div className="flex flex-col md:flex-row items-center py-10 lg:py-14">
                <div data-aos={"fade-right"} className="w-full md:w-1/2 mt-0 flex flex-col justify-center px-2 lg:px-0">
                    <div className="max-w-none flex justify-center items-center">
                        <img className="rounded-3xl" src={`${process.env.PUBLIC_URL}/images/8.jpg`} />
                    </div>
                </div>
                <div data-aos={"fade-left"} className="w-full md:w-1/2 pr-3 text-left lg:ml-16 mt-2 md:mt-0">
                    <div className="my-8 mr-0 lg:max-w-lg px-2 lg:px-0">
                        {parse(t('services.legal-service-ul-8'))}
                    </div>
                </div>
            </div>
            <div className="flex flex-col md:flex-row items-center py-10 lg:py-14">
                <div data-aos={"fade-right"} className="w-full md:w-1/2 pr-3 text-left">
                    <h1 className="text-4xl font-light leading-normal text-center md:text-left px-2 lg:px-0 text-gray-900 text-header-gradient max-w-3xl">
                        {parse(t('services.real-state-law'))}
                    </h1>
                    <p className="my-8 mr-0 lg:max-w-lg px-2 lg:px-0">
                        {parse(t('services.real-state-law-text'))}
                    </p>
                </div>
                <div data-aos={"fade-left"} className="w-full md:w-1/2 mt-0 flex flex-col justify-center px-2 md:px-0">
                    <div className="max-w-none flex justify-center items-center">
                        <img className="rounded-3xl" src={`${process.env.PUBLIC_URL}/images/9.jpg`} />
                    </div>
                </div>
            </div>
            <div className="flex flex-col md:flex-row items-center py-10 lg:py-14">
                <div data-aos={"fade-right"} className="w-full md:w-1/2 mt-0 flex flex-col justify-center px-2 lg:px-0">
                    <div className="max-w-none flex justify-center items-center">
                        <img className="rounded-3xl" src={`${process.env.PUBLIC_URL}/images/10.jpg`} />
                    </div>
                </div>
                <div data-aos={"fade-left"} className="w-full md:w-1/2 pr-3 text-left lg:ml-16 mt-2 md:mt-0">
                    <p className="my-8 mr-0 lg:max-w-lg px-2 lg:px-0">
                        {parse(t('services.legal-service-text'))}
                    </p>
                </div>
            </div>
            <div className="flex flex-col md:flex-row items-center py-10 lg:py-14 mb-10">
                <div data-aos={"fade-right"} className="w-full md:w-1/2 pr-3 text-left">
                    <h1 className="text-4xl font-light leading-normal text-center md:text-left px-2 lg:px-0 text-gray-900 text-header-gradient max-w-3xl">
                        {parse(t('services.real-state-tax-law'))}
                    </h1>
                    <p className="my-8 mr-0 lg:max-w-lg px-2 lg:px-0">
                        {parse(t('services.real-state-tax-law-text'))}
                    </p>
                </div>
                <div data-aos={"fade-left"} className="w-full md:w-1/2 mt-0 flex flex-col justify-center px-2 md:px-0">
                    <div className="max-w-none flex justify-center items-center">
                        <img className="rounded-3xl" src={`${process.env.PUBLIC_URL}/images/11.jpg`} />
                    </div>
                </div>
            </div>
        </div>
      </HelmetProvider>
  );
}

export default RegisterPropertyView;
