import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import {DEFAULT_LANG, I18N_KEY} from '../../constants';
import en from './en.json';
import es from './es.json';

const resources = {
  en,
  es,
};

export const i18nextInit = (): Promise<any> => {
  return i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      detection: {
        order: ['path', 'localStorage', 'sessionStorage'],
        lookupLocalStorage: I18N_KEY,
        lookupSessionStorage: I18N_KEY,
        lookupFromPathIndex: 0,
      },
      fallbackLng: DEFAULT_LANG,
      debug: ['development', 'test'].includes(process.env.NODE_ENV),
      resources,
      interpolation: {
        escapeValue: false
      }
    });
}

export function getSupportedLanguages() {
  return Object.keys(resources);
}

export default i18n;
