import React from 'react';
import {PropertyListItem} from '../../views/properties/models/property-list-item';
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import parse from 'html-react-parser';

interface PropertyListItemCardProps {
  property: PropertyListItem,
}

const PropertyListItemCard = ({property}: PropertyListItemCardProps) => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.resolvedLanguage;

  function commify(n:any) {
    if(n) {
        let parts = n.toString().split(".");
        const numberPart = parts[0];
        const decimalPart = parts[1];
        const thousands = /\B(?=(\d{3})+(?!\d))/g;
        return numberPart.replace(thousands, ".") + (decimalPart ? "," + decimalPart : "");
    } else {
        return 0;
    }
  }

  return (
      <Link to={`/${currentLanguage}/properties/${property.slug}`}>
          <article
              className="relative w-full h-80 bg-cover bg-center group rounded-lg overflow-hidden shadow-lg hover:shadow-2xl transition duration-300 ease-in-out"
              style={{ backgroundImage: `url("${property.imageUrl}")` }}>

              {property.operation &&
                  <div className={(property.operation === 'Alquiler') ||  (property.operation === 'Rent') ? `absolute top-0 right-0 p-2 bg-green-500 bg-opacity-75 rounded-r-md rounded-bl-md text-white uppercase` : `absolute top-0 right-0 p-2 bg-red-500 bg-opacity-75 rounded-r-md rounded-bl-md text-white uppercase`}>
                      {property.operation}
                  </div>
              }
              <div className="absolute bottom-0 bg-gray-900 bg-opacity-75 w-full h-28 group-hover:h-36 px-4 sm:px-6 lg:px-4 py-2 flex flex-col justify-items-start group-hover:justify-center items-start">
                  <div className="w-full flex justify-between items-center mb-2">
                      <h3 className="text-white text-md font-bold">
                          {property.name}
                      </h3>
                      <h2 className="text-white underline font-bold text-md">
                          {property.price ? commify(property.price) : t('card-properties.consult')} {property.price ? '€' : ''}
                      </h2>
                  </div>
                  <div className="w-full flex text-white group-hover:hidden">
                      {parse(`${property.extract}`)}
                  </div>
                  <div className="hidden w-full group-hover:grid group-hover:grid-cols-2 group-hover:gap-y-4 group-hover:transition group-hover:duration-300 group-hover:ease-in-out">
                      {!!property.property_size &&
                          <span className="flex items-center mb-1">
                            <svg className="h-6 w-6 text-white fill-current mr-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M432 184V59.31L235.3 256 432 452.7V328c0-4.422 3.58-8 7.998-8 4.424 0 8 3.578 8 8v144c0 4.426-3.584 8-8 8h-144c-4.398 0-7.998-3.6-7.998-8a8 8 0 0 1 8-8h124.7L224 267.3 27.31 464H152c4.4 0 8 3.6 8 8s-3.6 8-8 8H8c-4.424 0-8-3.6-8-8V328c0-4.4 3.58-8 8-8a7.996 7.996 0 0 1 7.998 8v124.7L212.7 256 15.1 59.31V184c0 4.4-2.68 8-7.1 8-4.424 0-8-3.6-8-8V40c0-4.43 3.584-8 8-8h144c4.4 0 8 3.59 8 8 0 4.42-3.6 8-8 8H27.31L224 244.7 420.7 48H296c-4.4 0-8-3.58-8-8 0-4.41 3.6-8 8-8h144c4.4 0 8 3.58 8 8v144c0 4.4-3.6 8-8 8s-8-3.6-8-8z"/></svg>
                            <span className="text-white ml-2 lg:ml-0">{property.property_size} <span className="hidden lg:inline">{parse(`${t('card-properties.area')}`)}</span></span>
                        </span>
                      }
                      {!!property.bedrooms &&
                          <span className="flex items-center">
                            <svg className="h-6 w-6 text-white fill-current mr-1" xmlns="http://www.w3.org/2000/svg"
                                 viewBox="0 0 24 24">
                                  <path
                                      d="M0 16L3 5V1a1 1 0 0 1 1-1h16a1 1 0 0 1 1 1v4l3 11v5a1 1 0 0 1-1 1v2h-1v-2H2v2H1v-2a1 1 0 0 1-1-1v-5zM19 5h1V1H4v4h1V4a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v1h2V4a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v1zm0 1v2a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1V6h-2v2a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V6H3.76L1.04 16h21.92L20.24 6H19zM1 17v4h22v-4H1zM6 4v4h4V4H6zm8 0v4h4V4h-4z"></path>
                            </svg>
                            <span className="text-white ml-2 lg:ml-0">{property.bedrooms} <span className="hidden lg:inline">{property.bedrooms > 1 ? t('card-properties.rooms') : t('card-properties.room')}</span></span>
                        </span>
                      }
                      {!!property.bathrooms &&
                          <span className="flex items-center mb-1">
                            <svg className="h-6 w-6 text-white fill-current mr-1" xmlns="http://www.w3.org/2000/svg"
                                 viewBox="0 0 24 24">
                                  <path fillRule="evenodd"
                                        d="M17.03 21H7.97a4 4 0 0 1-1.3-.22l-1.22 2.44-.9-.44 1.22-2.44a4 4 0 0 1-1.38-1.55L.5 11h7.56a4 4 0 0 1 1.78.42l2.32 1.16a4 4 0 0 0 1.78.42h9.56l-2.9 5.79a4 4 0 0 1-1.37 1.55l1.22 2.44-.9.44-1.22-2.44a4 4 0 0 1-1.3.22zM21 11h2.5a.5.5 0 1 1 0 1h-9.06a4.5 4.5 0 0 1-2-.48l-2.32-1.15A3.5 3.5 0 0 0 8.56 10H.5a.5.5 0 0 1 0-1h8.06c.7 0 1.38.16 2 .48l2.32 1.15a3.5 3.5 0 0 0 1.56.37H20V2a1 1 0 0 0-1.74-.67c.64.97.53 2.29-.32 3.14l-.35.36-3.54-3.54.35-.35a2.5 2.5 0 0 1 3.15-.32A2 2 0 0 1 21 2v9zm-5.48-9.65l2 2a1.5 1.5 0 0 0-2-2zm-10.23 17A3 3 0 0 0 7.97 20h9.06a3 3 0 0 0 2.68-1.66L21.88 14h-7.94a5 5 0 0 1-2.23-.53L9.4 12.32A3 3 0 0 0 8.06 12H2.12l3.17 6.34z"></path>
                            </svg>
                            <span className="text-white ml-2 lg:ml-0">{property.bathrooms} <span className="hidden lg:inline">{property.bathrooms > 1 ? t('card-properties.bathrooms') : t('card-properties.bathroom')}</span></span>
                        </span>
                      }
                      {!!property.garages &&
                          <span className="flex items-center">
                            <svg className="h-6 w-6 text-white fill-current mr-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M632.5 194.5c7.5 4.7 9.7 14.6 5 22-4.7 7.5-14.6 9.7-22 5L320 34.92 24.55 221.5c-7.48 4.7-17.357 2.5-22.076-5-4.718-7.4-2.487-17.3 4.984-22L311.5 2.472a15.85 15.85 0 0 1 17 0l304 192.028zM184 376c0-13.3 10.7-24 24-24s24 10.7 24 24-10.7 24-24 24-24-10.7-24-24zm272 0c0 13.3-10.7 24-24 24s-24-10.7-24-24 10.7-24 24-24 24 10.7 24 24zM252.2 176h135.6c28.6 0 53.7 18.9 61.5 46.4l20.8 75.7c25.3 13.7 41.9 39 41.9 69.9v128c0 8.8-7.2 16-16 16s-16-7.2-16-16v-32H160v32c0 8.8-7.2 16-16 16s-16-7.2-16-16V368c0-30.9 16.6-56.2 41-69.9l21.7-75.7c7.8-27.5 32.9-46.4 61.5-46.4zm0 32c-14.3 0-26.8 9.5-30.8 23.2L205.2 288H434.8l-16.2-56.8c-4-13.7-16.5-23.2-30.8-23.2H252.2zM480 368c0-26.5-21.5-48-48-48H208c-26.5 0-48 21.5-48 48v64h320v-64z"/></svg>
                            <span className="text-white ml-2 lg:ml-0">{property.garages} <span className="hidden lg:inline">{property.garages > 1 ? t('card-properties.garages') : t('card-properties.garage')}</span></span>
                        </span>
                      }
                  </div>
              </div>
          </article>
      </Link>
  );
}

export default PropertyListItemCard;
