import React, {useEffect} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {DEFAULT_LANG, DEFAULT_ROUTE, I18N_DEFAULT_NAMESPACE} from '../../constants';

const RootNavigator = () => {
  const navigate = useNavigate();
  const {lang} = useParams();
  const {i18n} = useTranslation();

  useEffect(() => {
    if (lang && i18n.hasResourceBundle(lang, I18N_DEFAULT_NAMESPACE)) {
      i18n.changeLanguage(lang)
        .then(() => navigate(`/${lang}/home`));
    } else if (i18n.resolvedLanguage) {
      i18n.changeLanguage(i18n.resolvedLanguage)
        .then(() => navigate(`/${i18n.resolvedLanguage}/home`));
    } else {
      i18n.changeLanguage(DEFAULT_LANG)
        .then(() => navigate(DEFAULT_ROUTE));
    }
  }, [i18n, navigate, lang]);

  return <></>;
}

export default RootNavigator;
