import React from 'react';
import {Link, useParams} from 'react-router-dom';
import classNames from 'classnames';
import {FlexRowFullCenterFixedNav} from '../layout/layout';
import {useTranslation} from 'react-i18next';
import DropdownLanguages from "../dropdown-languages/DropdownLanguages";
import {MobileNavLinks} from "./MobileNavLinks";
import useScrollPosition from "../../hooks/useScrollPosition";

interface HeaderProps {
  hidden?: boolean,
}

const ClassLi = ({children}: React.PropsWithChildren<any>) => {
  return (
    <li className="uppercase font-medium font-menu text-white px-4 py-3 flex items-center transition duration-150 ease-in-out">
      {children}
    </li>
  );
}

const Header = (props: HeaderProps) => {
  const { t, i18n } = useTranslation();
  const scrollPosition = useScrollPosition();

  return (
    <FlexRowFullCenterFixedNav {...props} >
        <div className={classNames('max-w-6xl', 'mx-auto px-5', 'sm:px-6')}>
            <div className="flex items-center justify-between h-16 md:h-24 lg:h-36 py-2">
              <div className="flex-shrink-0 mr-4 z-50">
                <Link to="/" className={classNames('block', 'w-16 md:w-24 lg:w-32')}>
                  <img src={`${process.env.PUBLIC_URL}/images/reesman_blanco.png`}/>
                </Link>
              </div>
              <div className={classNames('hidden', 'xl:block', 'flex', 'flex-grow', 'animate-logo')}>
                <ul className="flex flex-grow justify-end flex-wrap items-center">
                    <ClassLi>
                        <Link to={`/${i18n.resolvedLanguage}`}>{t('nav-items.home')}</Link>
                    </ClassLi>
                    <ClassLi>
                        <Link to={`/${i18n.resolvedLanguage}/properties?propertyStatus=sale`}>{t('nav-items.sale')}</Link>
                    </ClassLi>
                    <ClassLi>
                        <Link to={`/${i18n.resolvedLanguage}/properties?propertyStatus=rent`}>{t('nav-items.rent')}</Link>
                    </ClassLi>
                    <ClassLi>
                        <Link to={`/${i18n.resolvedLanguage}/register-property`}>{t('nav-items.register')}</Link>
                    </ClassLi>
                    <ClassLi>
                        <Link to={`/${i18n.resolvedLanguage}/services`}>{t('nav-items.services')}</Link>
                    </ClassLi>
                    <ClassLi>
                        <Link to={`/${i18n.resolvedLanguage}/contact`}>{t('nav-items.contact')}</Link>
                    </ClassLi>
                    <ClassLi>
                        <DropdownLanguages />
                    </ClassLi>
                    <li className={classNames('uppercase', 'font-medium', 'font-menu', 'text-white', 'px-3', 'py-3', 'flex', 'items-center', 'transition', 'duration-150', 'ease-in-out', { 'xl:block': scrollPosition > 10, 'hidden': scrollPosition < 10 })}>
                        <a href="#search-properties">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24"
                                 stroke="currentColor" strokeWidth="2">
                                <path strokeLinecap="round" strokeLinejoin="round"
                                      d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"/>
                            </svg>
                        </a>
                    </li>
                </ul>
              </div>
              <div className="-mr-2 flex items-center xl:hidden">
                <MobileNavLinks />
              </div>
            </div>
        </div>
    </FlexRowFullCenterFixedNav>
  );
}

export default Header;
